:root {
--blue: #3364FF !important;
--light-blue:#e9eefd;
--dark-blue: #3364FF;
--primary-blue:#3364FF;
--text-color:#222;
--light-dark:#919191;
--white:#fff;
--light-grey:#ddd;
--light-bg: #f6f8fa;
--dark: #111;
--resume-text:#777;
--resume-heading:#222;
--heading-resume:#222;
--resume-bg: #f5f5f5;
/* resume color */
--success-steel:#576d7b;
--essential-ecru:#bca97e;
--clever-blue:#1a409a;
--quality-azure:#0187de;
--delight-mint:#39c3b1;
--standout-ruby:#d0021b;
--savvy-salmon:#fe7a66;
--optimistic-amber:#e9a507;
--root-color:#808d97;

}